
/* purgecss start ignore */
/* Markdown Styles */
/* Global */
.markdown {
  @apply leading-relaxed text-base;
}

// @screen sm {
//   .markdown {
//     @apply text-base;
//   }
// }

// @screen lg {
//   .markdown {
//     @apply text-lg;
//   }
// }

/* Headers */
.markdown h1 {
  @apply text-3xl my-6 font-normal;
}

.markdown h2 {
  @apply text-xl my-8 mb-4 font-semibold;
}

.markdown h3 {
  @apply text-lg my-8 mb-4 font-semibold;
}

.markdown h4,
.markdown h5,
.markdown h6 {
  @apply text-base my-3 font-semibold;
}

/* @screen sm {
  .markdown h1,
  .markdown h2 {
    @apply text-2xl;
  }
  .markdown h3,
  .markdown h4,
  .markdown h5,
  .markdown h6 {
    @apply text-xl;
  }
} */

/* Links */
.markdown a {
  @apply text-blue-600;
}
.markdown a:hover {
  @apply underline;
}
/* Paragraph */
.markdown p {
  @apply mb-4;
}
/* Lists */
.markdown ul,
.markdown ol {
  @apply mb-4 ml-8;
}
.markdown li > p,
.markdown li > ul,
.markdown li > ol {
  @apply mb-0;
}
.markdown ol {
  @apply list-decimal;
}
.markdown ul {
  @apply list-disc;
}
/* Blockquotes */
.markdown blockquote {
  @apply p-2 mx-6 bg-gray-100 mb-4 border-l-4 border-gray-400 italic;
}
.markdown blockquote > p {
  @apply mb-0;
}
/* Tables */
.markdown th {
  text-align: left;
  @apply text-gray-800;
}
.markdown td,
.markdown th {
  @apply px-2 py-2 border border-gray-200;
}
.markdown tr:nth-child(odd) {
  @apply bg-gray-100;
}
.markdown table {
  @apply my-6 mb-8 text-sm;
}

.markdown .highlighter {
  @apply rounded-sm;
  font-size: .875em !important;
}

.markdown code {
  @apply bg-gray-100 rounded-sm px-1 py-1 text-blue-800;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  font-size: .875em;
}

/* Wrap any inline highlights `that are really long`, but don't modify
   the setting for codeblocks (inside ```), which are rendered in as:
   <pre><code>...
*/
.markdown :not(pre) > code.language-text {
  white-space: pre-line;
}
/* purgecss end ignore */

.markdown li p + pre {
  @apply mt-3 mb-4;
}
