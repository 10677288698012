// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';

.illustration-section::after {
    content: '';
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 870px;
    background-image: url(../images/illustration-section-01.svg);
    background-repeat: no-repeat;
    background-size: auto;
    left: 50%;
    top: 0;
    background-position: center top;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    z-index: -1;
}

.split-icon {
    display: inline-flex;
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(35, 46, 59, 0.1);
    margin-bottom: 24px;
}

.nowrap {
    white-space: nowrap;
}

.l-search-box {
    max-width: 784px;
    .btn {
        width: 50px;
        padding: 0;
        flex: none;
        margin-right: 0.5rem;
        img {
            width: 20px;
        }
    }
}

.trial-tag {
    font-size: 9px;
    letter-spacing: -0.5px;
    top: -3px;
    right: -3px;
    margin-right: -4px;
}


.dropdown-nav {
    display: none;

    &.show {
        display: block;
    }
}

@import './markdown.scss';


#sidebar {
  max-width: 224px;
}

body.no-widget {
    #meiqia-container {
        display: none !important;
    }
}


.menu-btn {
    width: 36px;
    height: 36px;
    margin: -4px -8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;

    .icon {
        transform: scale(0.75);
        position: relative;

        .bar {
            width: 24px;
            height: 2px;
            background: #222629;
            border-radius: 2px;
            margin: 6px 0;
            transition: all 0.2s;
            position: relative;
        }
    }

    &.open {
        .icon {
            .bar {
                &.b1 {
                    transform: rotate(45deg);
                    top: 8px;
                }
                &.b2 {
                    visibility: hidden;
                    opacity: 0;
                }
                &.b3 {
                    transform: rotate(-45deg);
                    top: -8px;
                }
            }
        }
    }
}

.menu-expanded {
    background: #fff;
    top: 60px;
    right: 0;
    left: 0;

    button > svg {
        display: none;
    }
}

.footer-logo {
    background: url('../images/unisms.logo.png') -5px -2px no-repeat;
    background-size: 135px 40px;
    width: 32px;
    height: 36px;
}

.md-screenshot {
    position: relative;
    img {
        width: 100%;
        max-width: 640px;
        display: block;
    }
    &.btm-cover {
        &::after {
            content: '';
            position: absolute;
            background: #fff;
            width: 100%;
            height: 2px;
            bottom: -1px;
        }
    }
}

.md-qrcode {
    width: 144px;
    display: inline-block;
    text-align: center;

    img {
        width: 144px;
    }

    .name {
        font-weight: 600;
        letter-spacing: 0.02em;
        margin: 0.125em 0 0.25em;
    }

    .tag {
        font-size: 0.65em;
        font-weight: 400;
        padding: 1px 4px;
        vertical-align: text-top;
        background-color: theme('colors.blue.600');

        &.pink {
            background-color: theme('colors.pink.500');
        }
    }

    .tips {
        font-size: 0.75em;
        opacity: 0.7;
    }

    + .md-qrcode {
        margin-left: 48px;
    }
}

.flag-icon {
    width: 24px;
    height: 24px;
    display: block;
}

.flex-grow {
    .img {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        minWidth: 100px;
        maxWidth: 100%;
    }
}

.form-input-disabled {
    background-color: #DFDFDF;
}

