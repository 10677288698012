.fixbottomright_ai .show_btn_new {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;
  background-color: #089bed;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fixbottomright_ai .show_btn_new:hover {
  background-color: #0089d2;
}

.fixbottomright_ai .show_btn_new:hover .side_content_ai {
  display: block;
}

.fixbottomright_ai .show_btn_new img {
  width: 30px;
  height: 30px;
}

.fixbottomright_ai .side_content_ai {
  position: absolute;
  right: 0;
  bottom: 55px;
  display: none;
  width: 350px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  color: black;
  font-size: 14px;
  background: white;
  padding-bottom: 0;
}

.fixbottomright_ai .side_content_ai::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -60px;
  width: 200px;
  height: 60px;
  background-color: red;
  opacity: 0;
}

.fixbottomright_ai .side_content_ai::after {
  content: "";
  display: none;
  position: absolute;
  bottom: -8px;
  right: 10px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: white transparent transparent transparent;
  transform: rotate(0deg);
}

.fixbottomright_ai .side_content_ai a {
  color: black;
  transition: 0.2s ease-in;
  text-decoration: none;
}

.fixbottomright_ai .side_content_ai .bg-primary {
  background-color: #089bed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.fixbottomright_ai .side_content_ai .bg-primary::after {
  content: "";
  display: block;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.fixbottomright_ai .side_content_ai .content_ai {
  position: relative;
  z-index: 2;
  padding: 20px 15px;
  overflow: initial;
}

.fixbottomright_ai .side_content_ai .content_ai .top {
  padding: 0 15px;
  position: relative;
}

.fixbottomright_ai .side_content_ai .content_ai .top .logo {
  width: 30px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 50px;
  object-position: left;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
}

.fixbottomright_ai .side_content_ai .content_ai .top .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  opacity: 0.5;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.fixbottomright_ai .side_content_ai .content_ai .top .close:hover {
  opacity: 1;
}

.fixbottomright_ai .side_content_ai .content_ai .top .title_ai {
  font-size: 30px;
  color: white;
  margin: 0px;
}
.fixbottomright_ai .side_content_ai p {
  margin: 0px;
}
.fixbottomright_ai .side_content_ai .content_ai .btns {
  padding: 0;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 15px;
}

.fixbottomright_ai .side_content_ai .content_ai .btns a {
  position: relative;
  width: 49%;
  height: 40px;
  line-height: 40px;
  background-color: transparent;
  color: gray;
  display: block;
  margin-bottom: 0;
  border-radius: 6px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
}

.fixbottomright_ai .side_content_ai .content_ai .btns a:hover {
  background-color: white;
}

.fixbottomright_ai .side_content_ai .content_ai .btns a:last-child {
  margin-bottom: 0;
}

.fixbottomright_ai .side_content_ai .content_ai .btns .phone:hover .qrcode {
  display: block;
}

.fixbottomright_ai .side_content_ai .content_ai .btns .qrcode {
  display: none;
  position: absolute;
  z-index: 10;
  left: -230px;
  bottom: 0;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  border-radius: 10px;
  background-color: white;
  padding: 15px;
  color: black;
  text-align: center;
}

.fixbottomright_ai .side_content_ai .content_ai .btns .qrcode img {
  width: 150px;
  height: 150px;
}

.fixbottomright_ai .side_content_ai .content_ai .btn-large {
  position: relative;
  margin-top: 80px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px;
  border-radius: 10px;
  padding: 15px;
  display: block;
}
.fixbottomright_ai .title_ai {
  font-size: 14px;
}
.fixbottomright_ai .side_content_ai .content_ai .btn-large:hover .title_ai {
  color: #089bed;
}

.fixbottomright_ai .side_content_ai .content_ai .btn-large .desc {
  font-size: 12px;
  color: #828c99;
}

.fixbottomright_ai .side_content_ai .content_ai .btn-large .icon {
  position: absolute;
  right: 15px;
  top: 25px;
  width: 15px;
  height: 15px;
}

.fixbottomright_ai .side_content_ai .bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  line-height: 44px;
  color: #828c99;
}

.fixbottomright_ai .side_content_ai .bottom p {
  line-height: 44px;
}

.fixbottomright_ai .side_content_ai .bottom a {
  color: #828c99;
}

.fixbottomright_ai .side_content_ai .bottom a:hover {
  text-decoration: underline;
  color: black;
}
