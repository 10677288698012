.loading {
  height: 100%;
  display: flex;
  color: #60697b;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.loading span {
  width: 8px;
  height: 100%;
  margin-left: 8px;
  border-radius: 4px;
  display: inline-block;
  background: #4fd1c5;
  animation: loadsaven 1.04s ease infinite;
  -webkit-animation: loadsaven 1.04s ease infinite;
}

@keyframes loadsaven {

  0%,
  100% {
    height: 40px;
    background: #4fd1c5;
  }

  50% {
    height: 60px;
    margin-top: -20px;
    background: #0078ff;
  }
}

@-webkit-keyframes loadsaven {

  0%,
  100% {
    height: 40px;
    background: #4fd1c5;
  }

  50% {
    height: 60px;
    margin-top: -20px;
    background: #0078ff;
  }
}

.loading span:nth-child(1) {
  margin-left: 0px;
}

.loading span:nth-child(2) {
  animation-delay: 0.13s;
  -webkit-animation-delay: 0.13s;
}

.loading span:nth-child(3) {
  animation-delay: 0.26s;
  -webkit-animation-delay: 0.26s;
}

.loading span:nth-child(4) {
  animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
}

.loading span:nth-child(5) {
  animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
}



html,
body,
div#root {
  height: 100%;
  margin: 0;
}

button.uni_cloud_login {
  padding: 0 14px;
  margin-left: 14px;
  border-radius: 2px;
  animation: 2s uni_cloud_login infinite alternate;
}

@keyframes uni_cloud_login {
  from {
    background: #0078ff;
  }

  to {
    background: #4fd1c5;
  }
}